<div class="box">
  @if (withCallWaiterRequestBill()) {
    <div class="top" coreActivitySection="CallWaiterToPay">
      <div class="header callWaiter" [class.hidden]="callWaiterRequestBill()">
        <img class="header__icon" src="/images/pay_bill.svg" alt="pay bill">
        <span class="header__text">{{ 'invoice.header' | translate }}</span>
      </div>
      <div class="container" [class.hidden]="callWaiterRequestBill()">
        <div class="group">
          <img class="group__icon" src="/images/ready_to_pay.svg" alt="">
          <div class="group__title">{{ 'invoice.callWaiterToPay.title' | translate }}</div>
          <div class="group__description">{{ 'invoice.callWaiterToPay.description' | translate }}</div>
        </div>
        <button
          class="call-waiter-btn"
          [disabled]="callWaiterMain() || callWaiterAnotherRound()"
          (click)="handlerCallWaiterToPay($event)"
          coreUserActivityClickTracking="CallWaiterToPay"
        >
          <img src="/images/request_bill.svg" alt="">
          <span>{{ 'invoice.callWaiterToPay.action' | translate }}</span>
        </button>
      </div>

      <div class="group active" [class.hidden]="!callWaiterRequestBill()">
        <div class="group__title">{{ 'invoice.callWaiterToPayCancel' | translate }}</div>
        <img class="logo" src="/images/logo.svg" alt=""/>

        <button
          class="call-waiter-cancel-btn"
          (click)="handlerCallWaiterToPay($event)"
          coreUserActivityClickTracking="CallWaiterToPayCancel"
        >
          {{ 'invoice.callWaiterToPay.actionCancel' | translate }}
        </button>
      </div>
    </div>
  } @else {
    <div class="top withQR">
      <div class="header">
        <img class="header__icon" src="/images/pay_bill.svg" alt="pay bill">
        <span class="header__text">{{ 'invoice.header' | translate }}</span>
      </div>
      <div class="group">
        <img class="group__icon" src="/images/ready_to_pay.svg" alt="">
        <div class="group__title">{{ 'invoice.title' | translate }}</div>
        <div class="group__description">{{ 'invoice.description' | translate }}</div>
      </div>
      @if (qrUrl(); as qrUrl) {
        <img class="qr-code" [src]="qrUrl" alt="">
      } @else {
        <img class="qr-code" src="/images/icon-planet.svg" alt="">
      }
      <div class="table-name">{{ 'invoice.table' | translate }} {{ table()?.tableName }}</div>
    </div>
  }
</div>
