@if (confetti) {
  <app-confetti/>
}

<div [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"/>
</div>

<core-background-player
  [style.height]="widgetUi.tabBarAutoHide && !userInteracts ? '100%' : 'calc(100% - 171px)'"
  [style.transition]="userInteracts ? 'cubic-bezier(0.57, 0.23, 0.08, 0.96) 0.65s': '0s'"
  style="
    position: absolute;
    top: 0;
    z-index: -1;
    width: 100%;
    background: transparent;
  "
/>

<app-tab-bar/>
