import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, output } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActivitySectionDirective, SpecialsService, UserActivityClickTrackingDirective } from '@core';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { TranslateModule } from '@ngx-translate/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { SwiperContainerComponent } from '@core/lib/components/swiper/swiper-container.component';
import { SwiperSlideComponent } from '@core/lib/components/swiper/swiper-slide.component';

@Component({
  selector: 'app-special',
  templateUrl: './special.component.html',
  styleUrl: './special.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    LoadingComponent,
    TranslateModule,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
    SwiperContainerComponent,
    SwiperSlideComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialTabComponent {

  private readonly specials$ = this.specialsService.specials$.pipe(
    map((specials) => {
      console.log('specials', specials);

      return specials?.filter(special => {
        const today = new Date();
        const start = new Date(special.scheduleStart);
        const end = new Date(special.scheduleEnd);
        return today >= start && today <= end;
      }).sort((a, b) => a.sequence - b.sequence)
    }),
  );

  public readonly close = output<void>();

  public readonly specials = toSignal(this.specials$);

  public readonly loading = toSignal(this.specialsService.loading$, {
    initialValue: true
  });

  constructor(
    private readonly specialsService: SpecialsService,
  ) {}

}
