<div class="categories" coreActivitySection="MenuCategories">
  @for (menu of menus(); track menu.id) {
    <button
      class="category"
      [class.happy-hours]="menu.happyHours"
      (click)="this.selectedMenuId$.next(menu.id)"
      [routerLink]="[menu.id]"
      routerLinkActive="active"
      [coreUserActivityClickTracking]="menu.name.split(' ').join('') + 'Open'"
    >
      @if (menu.happyHours) {
        <img class="icon happy-hours" src="/images/appy_hour_item.png" alt="">
      } @else if (menu.iconLocal) {
        <img class="icon" [src]="menu.iconLocal" alt="">
      } @else {
        <img class="icon" src="/images/menu.svg" alt="">
      }
      @if (!menu.happyHours) {
        <span class="name">
            {{ menu.name }}
          </span>
      }
    </button>
  }
</div>
@if (menus()?.length === 0 && !isLoading()) {
  @if (isSyncInProgress()) {
    <app-loading/>
  } @else {
    <div class="empty">
      <img src="/images/menu.svg" alt="" />
      <div>{{ 'menu.empty' | translate }}</div>
    </div>
  }
} @else {
  <div class="menu">
    @for (url of currentMenu()?.pagesLocal; track url) {
      <core-menu-image [url]="url"/>
    } @empty {
      <app-loading/>
    }
  </div>
}

