import { AfterViewChecked, ChangeDetectionStrategy, Component, ElementRef, OnDestroy } from '@angular/core';
import { register, SwiperContainer } from 'swiper/element/bundle';

register();

@Component({
  selector: 'swiper-container',
  standalone: true,
  template: '<ng-content />',
  changeDetection: ChangeDetectionStrategy.OnPush,
  inputs: [
    'autoplay',
    'autoplay-delay',
    'direction',
    'effect',
    'loop',
    'navigation',
    'pagination',
    'slides-per-view',
    'space-between',
    'speed',
  ],
  host: {
    '[attr.autoplay]': 'autoplay',
    '[attr.autoplay-delay]': 'autoplayDelay',
    '[attr.direction]': 'direction',
    '[attr.effect]': 'effect',
    '[attr.loop]': 'loop',
    '[attr.navigation]': 'navigation',
    '[attr.pagination]': 'pagination',
    '[attr.slides-per-view]': 'slidesPerView',
    '[attr.space-between]': 'spaceBetween',
    '[attr.speed]': 'speed',
  },
})
export class SwiperContainerComponent implements AfterViewChecked, OnDestroy {

  public autoplay: string | null = null;
  public autoplayDelay: string | null = null;
  public direction: string | null = null;
  public effect: string | null = null;
  public loop: string | null = null;
  public navigation: string | null = null;
  public pagination: string | null = null;
  public slidesPerView: string | null = null;
  public spaceBetween: string | null = null;
  public speed: string | null = null;

  constructor(
    public readonly elementRef: ElementRef<SwiperContainer>,
  ) {
    this.elementRef.nativeElement.setAttribute('init', 'false');
  }

  public ngAfterViewChecked() {
    if (this.elementRef.nativeElement.init) {
      this.elementRef.nativeElement.swiper.update();
    }
    else {
      this.elementRef.nativeElement.initialize();
    }
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.swiper.destroy();
  }

}
