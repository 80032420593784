import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  ActivitySectionDirective,
  CallWaiterService,
  RestaurantTableService,
  UserActivityClickTrackingDirective,
} from '@core';
import { BpScSectionsComponent } from '@app/components/bp-sc-sections/bp-sc-sections.component';
import { map } from 'rxjs/operators';
import { shareReplay } from 'rxjs';

@Component({
  selector: 'app-service-centre',
  templateUrl: './service-centre.component.html',
  imports: [
    TranslateModule,
    BpScSectionsComponent,
    UserActivityClickTrackingDirective,
    ActivitySectionDirective,
  ],
  styleUrls: ['./service-centre.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceCentreComponent {

  public readonly currentLanguage = toSignal(this.translate.onLangChange.pipe(
    map((event) => {
      return event.lang;
    }),
    shareReplay(1),
  ), { initialValue: this.translate.currentLang });

  public readonly table = toSignal(this.restaurantTable.table$);

  public readonly withCallWaiterMain = toSignal(this.callWaiterService.withMain$, {
    initialValue: false,
  });

  public readonly callWaiterMain = toSignal(this.callWaiterService.mainStatus$, {
    initialValue: true,
  });

  public readonly callWaiterRequestBill = toSignal(this.callWaiterService.requestBillStatus$, {
    requireSync: true,
  });

  public readonly callWaiterAnotherRound = toSignal(this.callWaiterService.anotherRoundStatus$, {
    requireSync: true,
  });

  constructor(
    private readonly translate: TranslateService,
    private readonly restaurantTable: RestaurantTableService,
    private readonly callWaiterService: CallWaiterService,
  ) {}

  public changeLanguage(language: string): void {
    this.translate.use(language);
  }

  public handlerCallWaiter($event: MouseEvent): void {
    if ($event.target instanceof HTMLElement) {
      $event.preventDefault();
      this.callWaiterService.setMain(!this.callWaiterMain());
    }
  }

}
