@let timer = status();

@if (timer.active || timer.now) {
  <div
    class="happy-hours"
    routerLink='/main/menu'
    [state]="{'tabHH': true}"
    coreActivitySection="HappyHours"
    coreUserActivityClickTracking="OpenHappyHours"
  >
    <img class="image" src="/images/appy-hours.png" alt="">
    <div class="box" [class.is_hh]="timer.now">

      @if (timer.active && !timer.now) {
        <div class="start-in">
          <div class="timer-label">{{ 'happyHour.startsIn' | translate }}</div>
          <div class="timer">{{ timer.activeCountdownTime }}</div>
        </div>
      }

      @if (timer.now) {
        <div class="menu">
          <span>{{ 'happyHour.viewMenu' | translate }}</span>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.57895 11.5789L14.6094 11.579L9.93625 16.2521C9.63816 16.5502 9.47367 16.947 9.47367 17.3684C9.47367 17.7899 9.63816 18.1867 9.93625 18.4848C10.2343 18.7829 10.6311 18.9474 11.0526 18.9474C11.4741 18.9474 11.8709 18.7829 12.169 18.4848L19.5374 11.1164C19.6073 11.0465 19.6685 10.9704 19.7232 10.8902C19.7253 10.8871 19.7281 10.885 19.7302 10.882C19.7795 10.809 19.8194 10.7309 19.8556 10.6512C19.8618 10.6379 19.8705 10.626 19.8762 10.6127C19.9078 10.5376 19.9291 10.459 19.9486 10.3804C19.954 10.3582 19.9636 10.3377 19.9682 10.3156C19.9889 10.2128 20 10.1069 20 10C20 9.89309 19.9889 9.78723 19.9681 9.68442C19.9635 9.66231 19.954 9.64176 19.9486 9.61965C19.9291 9.54102 19.9077 9.46239 19.8761 9.38735C19.8705 9.37399 19.8618 9.36215 19.8556 9.34879C19.8193 9.26915 19.7795 9.19098 19.7302 9.11801C19.7281 9.11493 19.7253 9.11286 19.7232 9.10981C19.6685 9.02961 19.6073 8.95356 19.5374 8.88364L12.169 1.51524C11.8704 1.21711 11.4741 1.05266 11.0527 1.05266C10.6312 1.05266 10.2349 1.21715 9.93629 1.51524C9.63817 1.81332 9.47371 2.21012 9.47371 2.6316C9.47371 3.05305 9.6382 3.44985 9.93629 3.74797L14.6094 8.42106L1.57895 8.42106C0.708281 8.42106 8.58321e-07 9.12934 7.82205e-07 10C7.06089e-07 10.8707 0.708281 11.5789 1.57895 11.5789Z"
              fill="white"
            />
          </svg>
        </div>
      }
    </div>
  </div>
}

