<div class="content" coreActivitySection="Specials">
  <div class="back-navigation" (click)="close.emit()" coreUserActivityClickTracking="CloseSpecials">
    <img src="/images/back_button.svg" alt="">
  </div>

  @if (loading()) {
    <app-loading/>
  } @else {
    @if (specials()) {
      <swiper-container
        class="swiper"
        autoplay="true"
        autoplay-delay="5000"
        pagination="true"
        direction="vertical"
        loop="true"
      >
        @for (slide of specials(); track slide.id; let i = $index) {
          <swiper-slide>
            <img class="slide" [src]="slide.contentLocal" alt="Image {{ i + 1 }}" />
          </swiper-slide>
        }
      </swiper-container>
    } @else {
      <div class="empty">
        <img src="/images/promos_offers.png" alt=""/>
        {{ 'serviceCenter.sections.promo.empty' | translate }}
      </div>
    }
  }
</div>

