import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenusService } from '@core';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';
import { PinchZoomComponent } from '@meddv/ngx-pinch-zoom';

@Component({
  selector: 'app-special-offer',
  templateUrl: './special-offer.component.html',
  styleUrls: ['./special-offer.component.scss'],
  imports: [
    PinchZoomComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialOfferComponent {

  public readonly pinchZoomProperties = {
    wheel: false,
    autoZoomOut: true,
    backgroundColor: 'rgba(240, 240, 243, 0.9)',
  };

  public readonly menu = toSignal(this.menus.getMenuForCurrentMedia().pipe(
    tap((menu) => {
      if (!menu) {
        this.router.navigate(['main/home']);
      }
    }),
  ));

  constructor(
    private readonly router: Router,
    private readonly menus: MenusService,
  ) {}

}
