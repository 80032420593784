import { Component, input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';

@Component({
  selector: 'app-qr-view',
  templateUrl: './qr-view.component.html',
  imports: [
    TranslateModule,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  styleUrls: ['./qr-view.component.scss'],
})
export class QrViewComponent {

  public readonly title = input('');
  public readonly imgSrc = input('');
  public readonly description = input<string>();
  public readonly description2 = input<string>();
  public readonly qrSrc = input<string>();
  public readonly moreData = input<unknown>();

}
