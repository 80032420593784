import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { BpMoreComponent } from './bp-more/bp-more.component';
import { TranslateModule } from '@ngx-translate/core';
import { BpRewardsComponent } from './bp-rewards/bp-rewards.component';
import { QrViewComponent } from '../qr-view/qr-view.component';
import { ActivitySectionDirective, UserActivityClickTrackingDirective } from '@core';
import { SpecialTabComponent } from '@app/pages/main-page/special-tab/special.component';

type State = 'main' | 'rewards' | 'promo' | 'gift' | 'more';

@Component({
  selector: 'app-bp-sc-sections',
  templateUrl: './bp-sc-sections.component.html',
  styleUrls: ['./bp-sc-sections.component.scss'],
  imports: [
    BpMoreComponent,
    TranslateModule,
    BpRewardsComponent,
    QrViewComponent,
    SpecialTabComponent,
    ActivitySectionDirective,
    UserActivityClickTrackingDirective,
  ],
  animations: [
    trigger('InOutAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('400ms ease-in-out', style({ transform: 'translateX(0%)' })),
      ]),
      transition(':leave', [
        animate('400ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BpScSectionsComponent {

  public state = signal<State>('main');

  public setState(state: State): void {
    this.state.set(state);
  }
}
