import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LoadingComponent } from '@app/components/loading/loading.component';
import { RouterLink } from '@angular/router';
import { RssItem, RssService } from '@app/services/rss.service';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  imports: [
    LoadingComponent,
    RouterLink,
  ],
  styleUrls: ['./news-page.component.scss'],
})
export class NewsPageComponent implements OnInit {
  public rssItems: RssItem[] = [];
  public showQR = false;

  constructor(
    private rssService: RssService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    const RSS_URL = 'https://cors.eu.org/https://nationalpost.com/feed';
    this.rssService.getRss(RSS_URL).subscribe(
      data => {
        this.rssItems = data;
      },
      error => {
        console.error('Error loading RSS feed', error);
      },
    );
  }

  sanitizeContent(content: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}
