<div class="sections" coreActivitySection="Sections">
  <div class="section rewards" (click)="setState('rewards')" coreUserActivityClickTracking="RewardsOpen">
    <img src="/images/rewards.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.rewards.rewards' | translate }}</span>
  </div>
  <div class="section promo" (click)="setState('promo')" coreUserActivityClickTracking="PromoOpen">
    <img src="/images/promos_offers.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.promo.promo' | translate }}</span>
  </div>
  <div class="section gift" (click)="setState('gift')" coreUserActivityClickTracking="GiftOpen">
    <img src="/images/gift_cards.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.gift.gift' | translate }}</span>
  </div>
  <div class="section more" (click)="setState('more')" coreUserActivityClickTracking="MoreOpen">
    <img src="/images/more.png" alt="rewards"/>
    <span>{{ 'serviceCenter.sections.more.more' | translate }}</span>
  </div>
</div>

@if (state() === 'more') {
  <app-bp-more
    @InOutAnimation
    (back)="setState('main')"
  >
  </app-bp-more>
}

@if (state() === 'rewards') {
  <app-bp-rewards
    @InOutAnimation
    (click)="setState('main')"
  ></app-bp-rewards>
}

@if (state() === 'gift') {
  <app-qr-view
    @InOutAnimation
    (click)="setState('main')"
    coreActivitySection="QRView"
  ></app-qr-view>
}

@if (state() === 'promo') {
  <app-special
    @InOutAnimation
    (close)="setState('main')"
  ></app-special>
}
