<div class="back-navigation" [routerLink]="showQR ? '/main/home/news' : '/main/home'" (click)="showQR = false">
  <img src="/images/back_button.svg" alt="">
</div>
@if (!showQR) {

  <div class="title">
    <img class="national" src="/images/national_post.svg" alt="National Post">
  </div>
}

@if (rssItems.length) {

  <div class="content">
    @if (!showQR) {

      <div class="news">
        @for (item of rssItems; track item) {

          <div class="news__item" (click)="showQR = true">
            <h3 class="news__item__title">{{ item.title }}</h3>
            <div class="news__item__description" [innerHTML]="sanitizeContent(item.description)"></div>
            <div class="news__item__details">
              <span>{{ item.creator }}</span>
              <span>{{ item.pubDate }}</span>
            </div>
          </div>
        }
      </div>
    } @else {

      <div (click)="showQR = false" class="news-qr">
        <img class="national" src="/images/national_post.svg" alt="National Post">
        <div class="qr-title">
          More news here
        </div>
        <img class="qr" src="/images/news-qr.svg" alt="QR-code">
      </div>
    }
  </div>
} @else {
  <app-loading/>
}
