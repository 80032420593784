import { isNgServe } from '@core';

import { Environment } from './type';

export const environment: Environment = {
  name: 'Oongalee ExcuseMe - Boston Pizza - DEV',
  machineName: 'boston-pizza.dev',
  production: true,
  serviceWorker: !isNgServe,
  debug: true,
  apiUrl: 'https://oongalee-exscuseme-srv-dev.r2.danavero.com',
  socketApi: 'wss://oongalee-exscuseme-srv-dev.r2.danavero.com',
  sentry: {
    dsn: '',
  },
  backgroundPlayer: null,
  backgroundAdPlayer: 'https://dwall-app-ad-player.netlify.app',
  backgroundAdPlayerColor: '#a10f25',
  update: {
    checkStrategy: 'every-minute',
    activateStrategy: 'restart-when-idle',
  },
  featureToggle: {
    idleClickGoTo: ['/main/home'],
    withTabBar: true,
    withCallWaiterMain: true,
    withCallWaiterRequestBill: false,
    withCallWaiterAnotherRound: true,
    withCallWaiterOnOffline: true,
    withInvoice: true,
    withServiceCentre: true,
    tabBarAutoHide: false,
    idleDelay: 30,
    ambientLight: {
      callWaiter: { r: 255, g: 0, b: 0, a: 1 },
      callWaiterToPay: { r: 0, g: 255, b: 0, a: 1 },
      callWaiterToRepeat: { r: 255, g: 255, b: 0, a: 1 },
    },
  },
};
